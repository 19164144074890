export const SBL = [
  {
    name: "Strategic",
    delay: 0.5,
    url: "https://youtu.be/mv7qibngEDM",
  },
  {
    name: "Relator",
    delay: 1,
    url: "https://youtu.be/wx4QIGBU744",
  },
  {
    name: "Individualization",
    delay: 2,
    url: "https://youtu.be/jB1-G1huIoA",
  },
  {
    name: "Empathy",
    delay: 3,
    url: "https://youtu.be/TpN-FA0oycQ",
  },
  {
    name: "Activator",
    delay: 4,
    url: "https://youtu.be/JwKkxmSd71s",
  },
]

export const skills = [
  {
    name: ["HTML", "CSS", "Javascript"],
  },
  {
    name: ["jQuery", "React", "Vue", "Gatsby"],
  },
  {
    name: ["Node.js", "Express.js"],
  },
  {
    name: ["Bootstrap", "Tailwind", "Materialize"],
  },
  {
    name: ["SQL", "MongoDB", "GraphQL"],
  },
  {
    name: ["Wordpress", "Shopify"],
  },
  {
    name: ["API", "Git", "NPM"],
  },
]

export const primary_location = [
  { type: "Residency:", description: "US, Alabama" },
  { type: "City:", description: "Birmingham" },
  { type: "Availability:", description: "Remote" },
]

export const deets = [
  { amount: "10+", details: "Years Pro XP" },
  { amount: "Over 250", details: "Projects" },
  { amount: "1", details: "Regret (Not Starting Sooner)" },
]
