import React, { useCallback, useEffect, useState } from "react"
import "animate.css"
import Layout from "../components/Layout"
import * as styles from "../styles/about.module.css"
import { githubDB } from "../db"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { FaArrowAltCircleUp } from "@react-icons/all-files/fa/FaArrowAltCircleUp"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaCoins } from "@react-icons/all-files/fa/FaCoins"
import { SBL, skills, primary_location, deets } from "../constants"

export default function About() {
  const [avatar, setAvatar] = useState("")
  const fetchGithub = useCallback(() => {
    const queryText = JSON.stringify(query)
    fetch(githubDB.baseURL, {
      method: "POST",
      headers: githubDB.headers,
      body: queryText,
    })
      .then(res => res.json())
      .then(data => {
        const { avatarUrl } = data.data.viewer
        setAvatar(avatarUrl)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    fetchGithub()
  }, [avatar])
  const strengths = SBL.map(({ name, delay, url }, index) => {
    return (
      <span key={index}>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          title={`Click here to see a video on the ${name} theme`}
          className={`animate__animated animate__fadeInLeftBig animate__delay-${delay}s ${styles.theme}`}
        >
          {name}
        </a>{" "}
      </span>
    )
  })

  const proficiency = skills.map(({ name }, index) => {
    return (
      <p key={index}>
        <FaCheckCircle />{" "}
        {name.map((n, index) => (
          <>
            {n}
            {index !== name.length - 1 ? ", " : ""}
          </>
        ))}
      </p>
    )
  })

  const location = primary_location.map(({ type, description }) => {
    return (
      <>
        <p>{type}</p>
        <p>{description}</p>
      </>
    )
  })

  const counter = deets.map(({ amount, details }, index) => (
    <span key={index}>
      <span className={styles.amount}>{amount}</span>
      <span>{details}</span>
    </span>
  ))

  return (
    <Layout title="About">
      <div className={styles.about}>
        <div>
          <div className={styles.intro}>
            <div className={styles.profile_pic}>
              <img src={avatar} alt="Profile Pic" width="140" />
              <div
                className={`animate__animated animate__flash animate__slower animate__infinite ${styles.online_outline}`}
              ></div>
              <div className={styles.online}></div>
            </div>
            <div className={styles.bio}>
              <div className={styles.name}>Derrick Strong</div>
              <div className={styles.position}>
                Software Engineer
                <br />
                {/* {`<code>`}Frontend{`</code>`} */}
                Consultant
              </div>
            </div>
          </div>
          <div className={styles.stats}>
            <div className={styles.location}>{location}</div>
            <hr />
            <div className={styles.proficiency}>{proficiency}</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.counter}>{counter}</div>
          <p>
            <span
              className={`animate__animated animate__fadeInLeftBig animate__delay-0.5s ${styles.hello}`}
            >
              Hello
            </span>
            ,{" "}
            {/* I am invested in building people and community through leadership. */}
            as an experienced software engineer and marketing consultant, I
            want to bring together those in the tech community in order to{" "}
            <strong>create</strong>, <strong>develop</strong> and{" "}
            <strong>deploy</strong> strategies and products that will move the
            world forward.
          </p>
          <p className={styles.motto}>
            "I’m gone bring change; even if I got to do it two&nbsp;cents at a
            time!"
            <span className={styles.author}>- Swagger (Apple TV)</span>
          </p>
          <p className={styles.strengths}>{strengths}</p>
          <p>
            As a leader, I feel a duty to impact the world around me through the
            use of my strengths{" "}
            <span className={styles.arrow_up}>
              <FaArrowAltCircleUp />
            </span>{" "}
            and talents. I just so happen to be a software engineer so my
            toolbox consist of creativity, source code and deliverables.
          </p>
          <p>
            Join me as I make some change and some change <FaCoins />.
          </p>
          <p>
            If you are interested in linking up for a project or other opportunities, send me a
            message on{" "}
            <a
              href="https://www.linkedin.com/in/derrickmstrong/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="social" />
            </a>
            .
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = {
  query: `
  {
    viewer {
      avatarUrl
    }
  }
`,
}
